import React, { useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';

import { Box } from '@mui/system';
import { AppBar, Backdrop, Badge, Button, CircularProgress, ClickAwayListener, Divider, Fade, Grid, Grow, IconButton, List, ListItem, ListItemText, Paper, Popover, Popper, Snackbar, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography, Zoom } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { makeFullNameWithNetIdText } from './commonHelperMethods';
import { observer } from 'mobx-react'
import { getUserFullNameWithNetIdText, setUserInfo } from './userInfo';
import { useNavigate } from 'react-router-dom';
import { isUserInfoComplete } from "./userInfo";
import LoginIcon from '@mui/icons-material/Login';

function Welcome(props) {
  const [inited, setInited] = useState(false);
  
  useEffect(() => {
    if (!inited) {
      setInited(true);
    }
  }, []);

  const navigate = useNavigate();

  const signInOrGoRoomList = () => {
    if (isUserInfoComplete()) {
      navigate('/rooms');
    } else {
      props.onGoAuthSignIn('/rooms');
    }
  };

  return (
      <div style={{height: '100%',
          position: 'absolute',
          left: 0,
          width: '100%',
          overflow: 'hidden'}}>
      <div style={{
            position:'absolute',
            left:0,
            top:0,
            width:'100vw',
            height:'100vh',
            zIndex:-100,
            backgroundColor: '#333',
            color: 'white',
            backgroundImage: 'url(/guest-bg.jpeg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
            filter: 'blur(6px)',
            WebkitFilter: 'blur(6px)',
            transform: 'scale(1.025)',
        }} />
      <div style={{display: 'flex', alignItems: 'center', height: '100vh', width: '100vw', justifyContent: 'center'}}>
      <div style={{textAlign: 'center'}}>
          <div style={{marginBottom: '2rem'}}>
            <img alt="128 Queue Virtual" src="/queue.svg" height="90" />
          </div>
          <Button variant="contained" onClick={signInOrGoRoomList} size="large"
                  color="success"
                  startIcon={<LoginIcon />}>sign in with 128 identity</Button>
      </div>
      </div>
      </div>
  );
}

// export default withStyles(styles)(Welcome);
export default Welcome;
