import React from 'react';
import axios from 'axios';

import { Button, Grid, Typography } from '@mui/material';
import { getUserEmail } from './userInfo';

class GoogleOAuth2 extends React.Component {
  state = {
    authUrl: ''
  };

  handleGoogleSignIn() {
    return () => {
      const apiPath = '/api/google/oauth2';
      axios.get(apiPath, 
        { params: {
          // success_redirect_to: this.props.success_redirect_to, 
          // fail_redirect_to: this.props.fail_redirect_to
        } })
        .then(res => {
          window.location.href = res.data.data.auth_url;
        })
        .catch(error => {
          this.props.onApiThrow(apiPath, error);
        });
    }
  }

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Google Authorization Required
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We need your Google Calendar permission for the purpose of creating an event, 
              so we can generate a conference link based on that event. 
              Please allow all permissions we asked. 
              Failure to authorize might lead to unable to use features. 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Please sign in with account: { getUserEmail() }
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button variant="contained" onClick={ this.handleGoogleSignIn() }>sign in</Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default GoogleOAuth2;
