import React, { useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';

import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { observer } from 'mobx-react'
import MessageIcon from '@mui/icons-material/Message';
import NotesIcon from '@mui/icons-material/Notes';
import GroupIcon from '@mui/icons-material/Group';
import ReactMarkdown from 'react-markdown'

const pluralize = (num, singular, plural) => num === null || num === undefined || num < 2 ?
    `${num === null || num === undefined || num === 0 ? "No" : num} ${singular}` : `${num} ${plural}`;

class RoomInfo extends React.Component {
  componentDidMount() {
    this.props.onLoadRoomInfo();
  }

  render() {
    if (Object.keys(this.props.roomInfo).length === 0) {
      return null;
    }

    return (
      <div>
          <div style={{paddingLeft: '10px', fontFamily: "'Ubuntu', sans-serif"}}>
              <Typography variant="h4" sx={{fontWeight: 'bold'}} mb={2}>
                { this.props.roomInfo.label }
              </Typography>

              <span style={{display: 'flex', color: 'gray',
                  alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                      <NotesIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                      <span>
                       { this.props.roomInfo.description }
                      </span>
              </span>

              <span style={{display: 'flex', color: 'gray', marginTop: '0.5rem',
                  alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                      <GroupIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                      <span>
                       { pluralize(this.props.roomInfo.helpee_num, "student", "students") } on the queue
                      </span>
              </span>
          </div>

        {
          this.props.roomInfo.announcement != null && this.props.roomInfo.announcement !== '' ?
          (
              <Paper elevation={5} style={{marginTop: '1.3rem', fontFamily: "'Ubuntu', sans-serif"}}>
                <Box px={3} pb={3} pt={1}>
                 <h3 style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                      <MessageIcon fontSize="inherit" sx={{paddingRight: '8px'}} />
                      <span>Announcement</span>
                  </h3>
                  <Box mt={1}>
                    <Typography variant="body1">
                      <ReactMarkdown>
                        { this.props.roomInfo.announcement }
                      </ReactMarkdown>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
          ) : null
        }
      </div>
    );
  }
}

export default RoomInfo;
