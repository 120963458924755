import React from 'react';
import axios from 'axios';

import { setUserInfo } from './userInfo';
import { Backdrop, Button, ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';

class CS128IdentityOAuth2Callback extends React.Component {
  state = {
    authState: 0, 
    message: '', 
  };

  componentDidMount() {
    const apiPath = '/api/auth/oauth2_callback';
    axios.get(apiPath + window.location.search)
      .then(res => {
        this.setState({ authState: 1 });
        setUserInfo(res.data.data.user_info);
        if (res.data.data.next_to == null || res.data.data.next_to === '') {
          window.location.href = '/rooms';
        } else {
          window.location.href = res.data.data.next_to;
        }
      })
      .catch(error => {
        const catchErrorCodes = [110, 111, 112, 113]
        if (error.response && error.response.status === 401 && 
            catchErrorCodes.includes(error.response.data.code)) {
          this.setState({ authState: 2, message: 'Error Code: ' + error.response.data.code, 
            next_to: error.response.data.data.next_to });
        } else {
          this.setState({ authState: 3 });
          this.props.onApiThrow(apiPath, error);
        }
      });
  }

  render() {
    var ret = null;
    switch (this.state.authState) {
      case 0:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  CS 128 Identity Authorizing ...
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 1:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  CS 128 Identity Authorization Success
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 2:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  CS 128 Identity Authorization Failed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please contact a course developer
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  { this.state.message }
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant="contained" onClick={ () => { this.props.onGoAuthSignIn(this.state.next_to) } }>retry sign in</Button>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 3:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  CS 128 Identity Authorization Failed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please contact a course developer
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant="contained" onClick={ () => { this.props.onGoAuthSignIn(this.state.next_to) } }>retry sign in</Button>
              </Grid>
            </Grid>
          </div>
        );
        break;
    }
    return (
      <div>
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.authState === 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {ret}
      </div>
    );
  }
}

export default CS128IdentityOAuth2Callback;
