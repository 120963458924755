import React from 'react';
import axios from 'axios';

import { Button, Grid, Typography } from '@mui/material';
import { getUserEmail } from './userInfo';

class ZoomOAuth2 extends React.Component {
  state = {
    authUrl: ''
  };

  handleZoomSignIn() {
    return () => {
      const apiPath = '/api/zoom/oauth2';
      // next_to is in window.location.search
      axios.get(apiPath + window.location.search)
        .then(res => {
          window.location.href = res.data.data.auth_url;
        })
        .catch(error => {
          this.props.onApiThrow(apiPath, error);
        });
    }
  }

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Zoom Authorization Required
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We need your Zoom meeting permission for the purpose of creating meetings. 
              Please use <strong>Sign in with SSO</strong>. 
              Failure to authorize might lead to unable to use features. 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Please sign in with account: { getUserEmail() }
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button variant="contained" onClick={ this.handleZoomSignIn() }>sign in</Button>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              Step 1:
            </Typography>
            <img src="/zoom_sign_in_instruction_1.png" width="100%" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              Step 2:
            </Typography>
            <img src="/zoom_sign_in_instruction_2.png" width="100%" />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ZoomOAuth2;
