import React, { useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';

import { Box } from '@mui/system';
import { AppBar, Backdrop, Badge, Button, CircularProgress, ClickAwayListener, Divider, Fade, IconButton, List, ListItem, ListItemText, Paper, Popover, Popper, Snackbar, Stack, Switch, Toolbar, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { makeFullNameWithNetIdText } from './commonHelperMethods';
import { observer } from 'mobx-react'
import { getUserFullNameWithNetIdText, setUserInfo } from './userInfo';
import { useNavigate } from 'react-router-dom';

const TopBarComponent = (props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      pushNotification: pushNotification, 
    };
  });

  const [loading, setLoading] = useState(false);
  const [inited, setInited] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationPopperState, setNotificationPopperState] = useState({
    open: false, 
    // anchorEl: null, 
  });
  const [notificationSnackbarState, setNotificationSnackbarState] = useState({
    open: false, 
    // notificationId: -1, 
    // notificationContent: '', 
  });

  useEffect(() => {
    if (!inited) {
      setInited(true);

      if ("Notification" in window) {
        Notification.requestPermission();
        // Promise.resolve(Notification.requestPermission()).then((result) => {
        //   if (result !== 'granted') {
        //     alert("Notification permission is not granted. We will not be able to push a notification when a staff is with you in the queue. ");
        //   }
        // });
        // Notification.requestPermission((result) => {
        //   if (result !== 'granted') {
        //     alert("Notification permission is not granted. We will not be able to push a notification when a staff is with you in the queue. ");
        //   }
        // });
      } else {
        alert("The browser does not support notification. You may still use the application, but we are unable to push a notification. We recommend you change a browser, so you will not miss notifications.");
      }
  
      setLoading((prev) => (prev + 1));
      const apiPath = '/api/notifications/get_unread_notifications';
      axios.get(apiPath)
        .then(res => {
          setNotifications(res.data.data);
          setLoading((prev) => (prev - 1));
        })
        .catch((error) => {
          setLoading((prev) => (prev - 1));
          props.onApiThrow(apiPath, error);
        });
    }
  });

  const pushNotification = (notification) => {
    setNotifications((prev) => ([ ...prev, notification ]));
    setNotificationSnackbarState({
      open: true, 
      notificationId: notification.notification_id, 
      notificationContent: notification.content
    });
    new Notification('CS 128: ' + notification.content);
  };

  const handleNotificationUpdateStatusToRead = (notificationId) => () => {
    setNotifications((prev) => (prev.filter((item) => item.notification_id !== notificationId)));
    const apiPath = '/api/notifications/update_status_to_read';
    axios.get(apiPath, { params: {
        notification_id: notificationId
      }})
      // .then(res => {
      // })
      .catch((error) => {
        props.onApiThrow(apiPath, error);
      });
  };

  const handleNotificationSnackbarOnClose = () => () => {
    setNotificationSnackbarState({ open: false });
  };

  const handleNotificationSnackbarDismissOnClick = () => () => {
    (handleNotificationSnackbarOnClose())();
    (handleNotificationUpdateStatusToRead(notificationSnackbarState.notificationId))();
  };

  const handleNotificationIconOnClick = () => (event) => {
    setNotificationPopperState({
      open: true, 
      anchorEl: event.currentTarget, 
    });
  };
  
  const handleNotificationPopperOnClose = () => () => {
    setNotificationPopperState({ open: false });
  };

  const navigate = useNavigate();

  const handleSignOut = () => () => {
    setLoading((prev) => (prev + 1));
    const apiPath = '/api/auth/sign_out';
    axios.get(apiPath)
      .then(res => {
        setLoading((prev) => (prev - 1));
        setUserInfo({});
        navigate('/');
      })
      .catch((error) => {
        setLoading((prev) => (prev - 1));
        props.onApiThrow('/notifications/get_unread_notifications', error);
      });
  };

  let notificationListItems = notifications.sort((a, b) => new Date(b.send_time) - new Date(a.send_time))
      .map((data, i) => {
    return (
      <div>
        { i > 0 ? (<Box mb={1}><Divider /></Box>) : null }
        <ListItem
          key={data.notification_id}
          secondaryAction={
            <IconButton onClick={handleNotificationUpdateStatusToRead(data.notification_id)}>
              <CheckIcon />
            </IconButton>
          }
        >
          <ListItemText
            sx={{whiteSpace: 'pre-line'}}
            primary={ data.content }
            secondary={ 
              'Sent: ' + new Date(data.send_time).toLocaleString() +
              ( data.sender_user != null ? 
              '\nSender: ' + makeFullNameWithNetIdText(data.sender_user)
              : '' )
            }
          />
        </ListItem>
      </div>
    );
  });

  if (notificationListItems.length === 0) {
    notificationListItems = (
      <Box m={2}>
        <Typography variant="body1" color="text.secondary">
          No unread notifications
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Backdrop 
        sx={{ color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
        }}
        open={loading > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={notificationSnackbarState.open}
        onClose={handleNotificationSnackbarOnClose()}
        message={notificationSnackbarState.notificationContent}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={(
          <Button color="warning" size="small" 
            onClick={handleNotificationSnackbarDismissOnClick()}>
            Dismiss
          </Button>
        )}
      />

      <Box sx={{ flowGrow: 1 }}>
        <AppBar position="static" color="transparent">
          <Toolbar>

            <img src="/queue.svg" height="50" width="100"
              onClick={() => { navigate('/rooms'); }}
              style={{ cursor: "pointer" }}
            />

            <Box sx={{ flexGrow: 1 }} />

            <Typography variant="body1">
              🤠 Howdy, { getUserFullNameWithNetIdText() }
            </Typography>

            <ClickAwayListener 
              onClickAway={handleNotificationPopperOnClose()}
            >
              <div>
                <IconButton
                  size="large"
                  color="primary"
                  onClick={handleNotificationIconOnClick()}
                  sx={{
                    ml: 1, 
                    mr: 2, 
                  }}
                >
                  <Badge badgeContent={notifications.length} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>

                <Popper
                  open={notificationPopperState.open}
                  anchorEl={notificationPopperState.anchorEl}
                  placement="bottom"
                  // transition
                >
                  {/* {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}> */}
                      <Paper>
                        <List
                          sx={{
                            maxHeight: 260, 
                            maxWidth: 360, 
                            overflow: 'auto', 
                          }}
                          dense
                        >
                          {notificationListItems}
                        </List>
                      </Paper>
                    {/* </Fade>
                  )} */}
                </Popper>
              </div>
            </ClickAwayListener>

            <Button variant="contained"
              onClick={handleSignOut()}
            >
              Sign Out
            </Button>

          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.topBarComponent = React.createRef();
  }

  handleNotificationOnReceived() {
    return (message) => {
      this.topBarComponent.current.pushNotification(message);
    };
  }

  render() {
    return (
      <div>
        <ActionCableConsumer
          channel="NotificationChannel"
          onReceived={this.handleNotificationOnReceived()}
        />

        <TopBarComponent
          onRef={this.topBarComponent}
          onApiThrow={this.props.onApiThrow}
        />
      </div>
    );
  }
}

export default TopBar;
