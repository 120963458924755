import React from 'react';
import axios from 'axios';
import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

class HelpeeSelfRemoveDialog extends React.Component {
  state = { loading: 0 };

  handleClickSubmit() {
    return () => {
      this.setState((prevState) => ({
        loading: prevState.loading + 1
      }));
      const apiPath = '/api/rooms/' + this.props.roomId + '/remove';
      axios.get(apiPath)
        .then(res => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1, 
          }));
          this.props.onUpdateHelpeeInfo(res.data.data.helpee)
          this.props.onClose()
        })
        .catch((error) => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1, 
          }));
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  render() {
    return (
      <Dialog 
        open={this.props.open}
        onClose={this.props.onClose}
        scroll="paper"
      >
        <Backdrop 
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Remove</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You will lose your current position in the queue. 
            We can't place you back after you remove yourself.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleClickSubmit()}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HelpeeSelfRemoveDialog;
