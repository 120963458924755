import React from 'react';
import axios from 'axios';

import { setUserInfo } from './userInfo';
import { Button, Grid, Typography } from '@mui/material';

class ReloadUserInfo extends React.Component {
  componentDidMount() {
    const apiPath = '/api/auth/user_info';
    axios.get(apiPath)
      .then(res => {
        setUserInfo(res.data.data);
        this.props.onGoRoomList();
      })
      .catch((error) => {
        this.props.onApiThrow(apiPath, error);
      });
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Reloading User Info ...
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default ReloadUserInfo;
