import ReactDOM from 'react-dom/client';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material";
import {ConfirmProvider, useConfirm} from 'material-ui-confirm';

import React, { useState, useEffect, useImperativeHandle } from 'react';

import axios from 'axios';

const reportError = (error, info) => {
  const apiPath = '/api/frontend_errors/report';
  axios.post(apiPath, {
    error: error, 
    info: info
  });
    // .then(res => {
    //   this.props.onGoRoomList();
    // })
    // .catch((error) => {
    //   this.props.onApiThrow(apiPath, error);
    // });
  // alert(error + '|' + JSON.stringify(info));
};

window.onerror = (msg, url, line) => {
  reportError('window.onerror', JSON.stringify({
    msg: msg, 
    url: url, 
    line: line, 
  }));
  
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    reportError(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const THEME = createTheme({
    typography: {
        "fontFamily": `"Ubuntu", sans-serif`,
    }
});

root.render(
    <ThemeProvider theme={THEME}>
        <ConfirmProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ConfirmProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
