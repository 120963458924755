import React from 'react';
import axios from 'axios';
import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

class RoomDeleteDialog extends React.Component {
  state = { loading: 0 };

  handleClickSubmit() {
    return () => {
      this.setState((prevState) => ({
        loading: prevState.loading + 1
      }));
      const apiPath = '/api/rooms/' + this.props.roomId + '/delete';
      axios.get(apiPath)
        .then(res => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1
          }));
          this.props.onUpdateRoomInfo(res.data.data.room);
          this.props.onClose()
        })
        .catch((error) => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1, 
          }));
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  render() {
    return (
      <Dialog 
        open={this.props.open}
        onClose={this.props.onClose}
        scroll="paper"
      >
        <Backdrop 
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Room Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This will move all users out of that room immediately. 
            The queue will be destroyed. 
            If you wish to keep the information, you may hide the room instead. 
            Hidden rooms are visible to admin only. 
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleClickSubmit()}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RoomDeleteDialog;
