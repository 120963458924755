import React, { useEffect, useState, useImperativeHandle } from 'react';
// import './index.css';
// import App from './App';
import GoogleOAuth2 from './GoogleOAuth2';
import GoogleOAuth2Callback from './GoogleOAuth2Callback';
import ZoomOAuth2 from './ZoomOAuth2';
import ZoomOAuth2Callback from './ZoomOAuth2Callback';
import ReloadUserInfo from './ReloadUserInfo';
// import reportWebVitals from './reportWebVitals';
import axios from 'axios';

import { BrowserRouter, Route, Routes, useLocation, useNavigate  } from "react-router-dom";
import { ActionCableProvider } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import RoomList from './RoomList';
import TopBar from './TopBar';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, Switch, Typography } from '@mui/material';
import Room from './Room';
import Welcome from './Welcome';
import CS128IdentityOAuth2Callback from './CS128IdentityOAuth2Callback';
import { hasAdminAuth, hasStaffAuth, isUserInfoComplete } from './userInfo';
import { observer } from 'mobx-react'
import ErrorPage from './ErrorPage';
import WarningIcon from '@mui/icons-material/Warning';

axios.defaults.withCredentials = true;

function SignInRequire(props) {
  if (!isUserInfoComplete()) {
    props.onGoAuthSignIn(window.location.href);
  }
  return (null);
}

const AppRoutes = observer((props) => {
  const location = useLocation();

  useEffect(() => {
    setErrorPageInfo(null);
  }, [location]);

  useImperativeHandle(props.onRef, () => {
    return {
      showErrorPage: showErrorPage, 
    };
  });

  const [errorPageInfo, setErrorPageInfo] = useState(null);

  const navigate = useNavigate();

  const handleEnterRoom = (roomId) => () => {
    if (hasStaffAuth()) {
      navigate('/rooms/' + roomId + '/staff');
    } else {
      navigate('/rooms/' + roomId);
    }
  };

  const handleGoRoomList = () => () => {
    navigate('/rooms');
  };

  const showErrorPage = (errorInfo) => {
    setErrorPageInfo(errorInfo);
  };

  const routes = (
    <Routes>
      <Route path='*' element={
        <ErrorPage
          errorPageInfo={{ status: 404, description: 'Page not found' }}
        />
      } />

      <Route path="/rooms" element={
        <RoomList onApiThrow={props.onApiThrow} handleEnterRoom={handleEnterRoom} />
      } />
      <Route path="/rooms/:roomId/*" element={
        <Room onApiThrow={props.onApiThrow} onGoRoomList={handleGoRoomList()} />
      } />
  
      <Route path="/google_oauth2" element={
        <GoogleOAuth2 onApiThrow={props.onApiThrow} />
      } />
      <Route path="/google_oauth2_callback" element={
        <GoogleOAuth2Callback onApiThrow={props.onApiThrow} />
      } />

      <Route path="/zoom_oauth2" element={
        <ZoomOAuth2 onApiThrow={props.onApiThrow} />
      } />
      <Route path="/zoom_oauth2_callback" element={
        <ZoomOAuth2Callback onApiThrow={props.onApiThrow} />
      } />
  
      <Route path="/reload_user_info" element={
        <ReloadUserInfo onApiThrow={props.onApiThrow} onGoRoomList={handleGoRoomList()} />
      } />
      <Route path="/identity_oauth2_callback" element={
        <CS128IdentityOAuth2Callback onApiThrow={props.onApiThrow} onGoAuthSignIn={props.onGoAuthSignIn} />
      } />
    </Routes>
  );

  return (
    <div>
      { errorPageInfo == null ? routes : (
        <ErrorPage errorPageInfo={errorPageInfo} />
      ) }
    </div>
  );
});

const AlertStack = observer((props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      createAlert: createAlert, 
    };
  });

  const [alerts, setAlerts] = useState([]);

  const createAlert = (severity, content) => {
    setAlerts((prevAlerts) => {
      let newAlerts = [ ...prevAlerts ];
      newAlerts.push({ severity: severity, content: content });
      return newAlerts;
    });
  };

  const destroyAlert = (i) => {
    setAlerts((prevAlerts) => {
      let newAlerts = [ ...prevAlerts ];
      newAlerts.splice(i, 1);
      return newAlerts;
    });
  };

  const alertsInStack = alerts.map((data, i) => (
    <Alert key={i} onClose={() => { destroyAlert(i); }} severity={data.severity}>
      {data.content}
    </Alert>
  ));

  return (
    <Stack>
      {alertsInStack}
    </Stack>
  );
});

const AlertDialog = observer((props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      displayAlert: displayAlert, 
    };
  });

  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  const displayAlert = (alertContent) => {
    setOpen(true);
    setContent(alertContent);
  };

  return (
    <Dialog open={open} onClose={() => { setOpen(false); }}>
      {/* <DialogTitle></DialogTitle> */}
      <DialogContent aligin="buttom">
        <WarningIcon sx={{
          marginRight: 2, 
          verticalAlign: 'middle', 
        }} />
        <Typography display="inline" variant="body1" sx={{
          verticalAlign: 'middle', 
        }}>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setOpen(false); }}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  )
});

var goAuthSignInRequesting = false;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.alertStackRef = React.createRef();
    this.alertDialogRef = React.createRef();
    this.appRoutesRef = React.createRef();
  }

  goAuthSignIn(next_to) {
    if (goAuthSignInRequesting) { return; }
    goAuthSignInRequesting = true;
    const apiPath = '/api/auth/oauth2';
    axios.get(apiPath, 
      { params: {
        next_to: next_to
      } })
      .then(res => {
        window.location.href = res.data.data.auth_url;
        // goAuthSignInRequesting = false;
      })
      .catch((error) => {
        this.props.onApiThrow(apiPath, error);
        goAuthSignInRequesting = false;
      });
  }
  
  handleAlertCreate() {
    return (severity, content) => {
      this.setState((prevState) => {
        prevState.alerts.push({ severity: severity, content: content });
        return {
          alerts: prevState.alerts
        };
      });
    }
  }

  handleApiThrow() {
    return (apiPath, error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 401 && error.response.data.code === 10) {
          this.goAuthSignIn(window.location.href);
        } else if (error.response.status === 401 && error.response.data.code === 50) {
          window.open('/google_oauth2');
        } else if (error.response.status === 401 && error.response.data.code === 60) {
          window.open('/zoom_oauth2');
        } else {
          var description;
          if (error.response.data.show_message_only) {
            description = (error.response.data.message != null ? error.response.data.message : '');
          } else {
            description = apiPath + ' [10] ' +
              '[' + error.response.status + '] ' + 
              (error.response.data.message != null ? error.response.data.message : '');
          }
          if (error.response.data.show_error_page) {
            this.appRoutesRef.current.showErrorPage({ status: error.response.status, description: description });
          } else if (error.response.data.show_message_only) {
            this.alertDialogRef.current.displayAlert(description);
          } else {
            this.alertStackRef.current.createAlert('error', description);
          }
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        this.alertStackRef.current.createAlert('error', apiPath + ' [11] ' +
          (error.message != null ? error.message : ''));
      } else {
        // Something happened in setting up the request that triggered an Error
        this.alertStackRef.current.createAlert('error', apiPath + ' [12] ' +
          (error.message != null ? error.message : ''));
      }
    };
  }

  render() {
    return (
      <div>
        <ActionCableProvider url={window.location.origin.replace(/^http/, 'ws') + '/cable'}>
          <BrowserRouter>

            <Routes>
              <Route path="/rooms/*" element={
                <SignInRequire onApiThrow={this.handleApiThrow()} onGoAuthSignIn={this.goAuthSignIn} />
              } />

              <Route path="/google_oauth2" element={
                <SignInRequire onApiThrow={this.handleApiThrow()} onGoAuthSignIn={this.goAuthSignIn} />
              } />
              <Route path="/google_oauth2_callback" element={
                <SignInRequire onApiThrow={this.handleApiThrow()} onGoAuthSignIn={this.goAuthSignIn} />
              } />

              <Route path="/zoom_oauth2" element={
                <SignInRequire onApiThrow={this.handleApiThrow()} onGoAuthSignIn={this.goAuthSignIn} />
              } />
              <Route path="/zoom_oauth2_callback" element={
                <SignInRequire onApiThrow={this.handleApiThrow()} onGoAuthSignIn={this.goAuthSignIn} />
              } />
            </Routes>

            <Routes>
              <Route path="/rooms/*" element={
                <TopBar onApiThrow={this.handleApiThrow()} 
                />
              } />
            </Routes>

            <AlertStack onRef={this.alertStackRef} />

            <AlertDialog onRef={this.alertDialogRef} />

            <Routes>
              <Route path="*" element={
                <Box m={3}>
                  <div style={{minHeight: '55vh'}}>
                    <AppRoutes
                      onRef={this.appRoutesRef}
                      onApiThrow={this.handleApiThrow()}
                      onGoAuthSignIn={this.goAuthSignIn}
                    />
                  </div>
                  <Grid container spacing={1} mt={10}>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                      <img src="/cs128-login.svg" height="76.92" width="100" />
                      <Typography display="inline" sx={{
                        fontWeight: 'bold', 
                        color: '#3c3c3c', 
                      }}>
                        &nbsp;Dev Team
                      </Typography>
                      <Box sx={{ m: 5 }} />
                      <Typography variant="body1" color="text.secondary" display="inline">
                        © Copyright 2022 by CS 128 Instructional Development Team. All rights reserved.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              } />
              <Route path="/" element={
                <Welcome onApiThrow={this.handleApiThrow()} onGoAuthSignIn={this.goAuthSignIn} />
              } />
            </Routes>
            
          </BrowserRouter>
        </ActionCableProvider>
      </div>
    );
  }
}

export default App;
