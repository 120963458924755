import React from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';

class GoogleOAuth2Callback extends React.Component {
  state = {
    authState: 0, 
    message: '', 
  };

  componentDidMount() {
    const apiPath = '/api/google/oauth2_callback';
    axios.get(apiPath + window.location.search)
      .then(res => {
        this.setState({ authState: 1 });
        // window.location.replace(res.data.data.success_redirect_to);
      })
      .catch(error => {
        if (error.response && error.response.status === 401 && error.response.data.code === 50) {
          this.setState({ authState: 2, message: error.response.data.message });
        } else {
          this.setState({ authState: 3 });
          this.props.onApiThrow(apiPath, error);
        }
      });
  }

  handleGoogleSignIn() {
    return () => {
      const apiPath = '/api/google/oauth2';
      axios.get(apiPath, 
        { params: {
          // success_redirect_to: this.props.success_redirect_to, 
          // fail_redirect_to: this.props.fail_redirect_to
        } })
        .then(res => {
          window.location.href = res.data.data.auth_url;
        })
        .catch(error => {
          this.props.onApiThrow(apiPath, error);
        });
    }
  }

  render() {
    var ret = null;
    switch (this.state.authState) {
      case 0:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Google Authorizing ...
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 1:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Google Authorization Success
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  You can now close this page to continue. 
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 2:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Google Authorization Failed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  { this.state.message }
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant="contained" onClick={ this.handleGoogleSignIn() }>retry sign in</Button>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 3:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Google Authorization Failed
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant="contained" onClick={ this.handleGoogleSignIn() }>retry sign in</Button>
              </Grid>
            </Grid>
          </div>
        );
        break;
    }
    return (
      <div>
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.authState === 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {ret}
      </div>
    );
  }
}

export default GoogleOAuth2Callback;
