import React from 'react'
import axios from 'axios'
import { Backdrop, Button, Box, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

class RoomCategoryUpdateDialog extends React.Component {
  state = { loading: 0, ready: false, data: {} };

  handleInputChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value })
    }
  }

  componentDidUpdate() {
    if (this.props.open && !this.state.ready && this.state.loading <= 0) {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
      }))
      const apiPath = '/api/rooms/' + this.props.roomId + '/categories/admin_list'
      axios.get(apiPath)
        .then(res => {
          const data = {
            categories: res.data.data.categories
          }
          this.setState((prevState) => ({
            loading: prevState.loading - 1,
          }))
          this.setState({ data: data, ready: true })
        })
        .catch((error) => {
          this.setState((prevState) => ({
            loading: prevState.loading - 1,
          }))
          this.props.onClose()
          this.props.onApiThrow(apiPath, error)
        })
    }
  }

  handleAdd() {
    return () => {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
      }))
      axios.get('/api/rooms/' + this.props.roomId + '/categories/create/', {
        params: {
          name: this.state.new_category_name,
        }
      }).then((response) => {
        this.setState((prevState) => {
          prevState.loading = prevState.loading - 1
          prevState.data.categories = response.data.data.categories
          prevState.new_category_name = ''
          return prevState
        })
        this.props.onLoadRoomInfo();
      }).catch((error) => {
        this.setState((prevState) => ({
          loading: prevState.loading - 1,
          new_category_name: '',
        }))
        console.log(error)
      })
    }
  }

  handleDelete(category) {
    return () => {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
      }))
      axios.get('/api/rooms/' + this.props.roomId + '/categories/' + category.id + '/delete', { }).then((response) => {
        this.setState((prevState) => {
          prevState.loading = prevState.loading - 1
          prevState.data.categories = response.data.data.categories
          return prevState
        })
        this.props.onLoadRoomInfo();
      }).catch((error) => {
        this.setState((prevState) => ({
          loading: prevState.loading - 1,
        }))
        console.log(error)
      })
    }
  }

  handleClose() {
    return () => {
      this.setState({ ready: false })
      this.props.onClose()
    }
  }

  render() {
    if (!this.props.open) {
      return null
    }

    const form = this.state.ready ? (
      <div>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {this.props.adminView ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Add Category</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Category Name</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.new_category_name}
                      onChange={this.handleInputChange('new_category_name')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={this.handleAdd()}>Add</Button>
                </Grid>
                <Grid item xs={12} mt={5}>
                  <Typography variant="h6">Edit Categories</Typography>
                </Grid>
                {Object.keys(this.state.data.categories).map((key) => {
                  const category = this.state.data.categories[key]
                  return (
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" color="secondary" mr={5}>{category.name}</Typography>
                        <Button color="error" startIcon={<DeleteIcon />} onClick={this.handleDelete(category)}>
                          Delete
                        </Button>
                      </Box>
                    </Grid>
                  )
                })}

              </>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose()}>Done</Button>
        </DialogActions>
      </div>
    ) : null

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose()}
        scroll="paper"
      >
        <DialogTitle>Room Categories</DialogTitle>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {form}
      </Dialog>
    )
  }
}

export default RoomCategoryUpdateDialog
