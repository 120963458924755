import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
    Backdrop,
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { makeFullNameWithNetIdText } from './commonHelperMethods';

function StudentHistory(props) {
  const [inited, setInited] = useState(false);
  const [helpHistories, setHelpHistories] = useState([]);
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    if (!inited && props.roomId != null) {
      setInited(true);
      setLoading((prev) => (prev + 1));
      const apiPath = '/api/rooms/' + props.roomId + '/student/help_history';
      axios.get(apiPath)
        .then(res => {
          setHelpHistories(res.data.data.reverse());
          setLoading((prev) => (prev - 1));
        })
        .catch((error) => {
          setLoading((prev) => (prev - 1));
          props.onApiThrow(apiPath, error);
        });
    }
  });

  const getWaitTime = (placed, seen) => {
      const waitTime = Math.floor((placed - seen) / 60000);
      return waitTime > 0 ? `${waitTime} m` : "No Wait"
  };

  const openFeedbackForm = (caseId) => {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLScxUHLqEJjcZAtRO3oc8tKD_6ojrLI7q_bWKwvr54FDEN9aXQ/viewform?usp=pp_url&entry.31016190='
        + caseId);
      return true;
  };

  const tableBody = helpHistories.map((helpHistory, i) => (
    <TableRow key={i}>
      <TableCell align={'center'}>
        <img src={helpHistory.avatar} style={{borderRadius: '50%', width: '3rem', height: '3rem', marginBottom: '5px'}} />
        <br/>
        <a href={'mailto:' + helpHistory.net_id  + '@illinois.edu'}>
          {helpHistory.first_name + ' ' + helpHistory.last_name + ' (' + helpHistory.net_id + ')'}
        </a>
      </TableCell>
      <TableCell>
        { new Date(helpHistory.start_help_time).toLocaleString() +
            ' ~ ' + new Date(helpHistory.stop_help_time).toLocaleString()}
      </TableCell>
      <TableCell>
          {getWaitTime(new Date(helpHistory.helpee_join_time), new Date(helpHistory.start_help_time))}
      </TableCell>
      <TableCell>
        {helpHistory.summary}
      </TableCell>
      <TableCell>
          <Button
              variant="contained"
              onClick={() => openFeedbackForm(helpHistory.case_number)}
          >
              Feedback
          </Button>

      </TableCell>
    </TableRow>
  ));

  return (
    <React.Fragment>
      <Backdrop 
        sx={{ color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
        }}
        open={loading > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Helper</TableCell>
              <TableCell>Seen</TableCell>
              <TableCell>You Waited</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Provide Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBody}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default StudentHistory;
