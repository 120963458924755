import React, { useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';

import { Backdrop, Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import RoomUpdateDialog from './RoomUpdateDialog';
import { hasAdminAuth, hasStaffAuth } from './userInfo';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { observer } from 'mobx-react'
import RoomDeleteDialog from './RoomDeleteDialog';
import NotesIcon from "@mui/icons-material/Notes";
import GroupIcon from "@mui/icons-material/Group";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import BedtimeOffIcon from '@mui/icons-material/BedtimeOff';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const pluralize = (num, singular, plural) => num === null || num === undefined || num < 2 ?
    `${num === null || num === undefined || num === 0 ? "No" : num} ${singular}` : `${num} ${plural}`;

const iconAndTextStyle = {display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'};

const RoomListComponent = observer((props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      updateRoomList: updateRoomList, 
    };
  });
  
  const [inited, setInited] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(0);
  const [roomUpdateDialogOpen, setRoomUpdateDialogOpen] = useState(false);
  const [roomUpdateDialogRoomId, setRoomUpdateDialogRoomId] = useState(-1);
  const [roomDeleteDialogOpen, setRoomDeleteDialogOpen] = useState(false);
  const [roomDeleteDialogRoomId, setRoomDeleteDialogRoomId] = useState(-1);
  const [adminView, setAdminView] = useState(false);

  useEffect(() => {
    if (!inited) {
      setInited(true);
      setLoading((prev) => (prev + 1));
      const apiPath = '/api/rooms';
      axios.get(apiPath)
        .then(res => {
          setRooms(res.data.data);
          setLoading((prev) => (prev - 1));
        })
        .catch((error) => {
          setLoading((prev) => (prev - 1));
          props.onApiThrow(apiPath, error);
        });
    }
  });

  const handleRoomUpdateDialogOpen = (roomId) => () => {
    setRoomUpdateDialogOpen(true);
    setRoomUpdateDialogRoomId(roomId);
  };

  const handleUpdateDialogClose = () => () => {
    setRoomUpdateDialogOpen(false);
    setRoomUpdateDialogRoomId(-1);
  };

  const handleRoomDeleteDialogOpen = (roomId) => () => {
    setRoomDeleteDialogOpen(true);
    setRoomDeleteDialogRoomId(roomId);
  };

  const handleDeleteDialogClose = () => () => {
    setRoomDeleteDialogOpen(false);
    setRoomDeleteDialogRoomId(-1);
  };

  const updateRoomList = (updateInfo) => {
    setRooms((prevRooms) => {
      var newRooms = [ ...prevRooms ];
      if (updateInfo.deleted) {
        newRooms = newRooms.filter((item) => item.room_id !== updateInfo.room_id); 
      } else {
        var updated = false;
        for (var i = 0; i < newRooms.length; ++i) {
          if (newRooms[i].room_id > updateInfo.room_id) {
            newRooms.splice(i, 0, updateInfo);
            updated = true;
            break;
          } else if (newRooms[i].room_id === updateInfo.room_id) {
            Object.keys(updateInfo).forEach((key) => {
              newRooms[i][key] = updateInfo[key];
            });
            updated = true;
            break;
          }
        }
        if (!updated) {
          newRooms.push(updateInfo);
        }
      }
      return newRooms;
    });
  };

  const handleCreate = () => () => {
    setLoading((prev) => (prev + 1));
    const apiPath = '/api/rooms/create';
    axios.get(apiPath, { params: {
        label: 'New Room'
      }})
      .then(res => {
        updateRoomList(res.data.data.room);
        setLoading((prev) => (prev - 1));
      })
      .catch((error) => {
        setLoading((prev) => (prev - 1));
        props.onApiThrow(apiPath, error);
      });
  };

  const handleAdminViewSwitchChange = () => (event) => {
    setAdminView(event.target.checked);
  };

  let isEmptyList = true;
  let listItems = rooms.map((data) => {
    if (data.status === 'hidden' && !adminView) {
      return null;
    }
    isEmptyList = false;
    return (
      <Grid item key={data.room_id} style={{
          display: 'flex', 
          minWidth: '300px', 
        }}
        xs={4}
      >
        <Card sx={{
            display: 'flex', 
            justifyContent: 'space-between', 
            flexDirection: 'column', 
            width: '100%', 
          }}
          elevation={10}
        >
          <CardContent
            sx={{ cursor: "pointer" }}
            onClick={props.handleEnterRoom(data.room_id)}
          >

            <Typography variant="h5" component="div" sx={{fontWeight: 'bold'}}>
              { data.label }
            </Typography>

            <p style={{marginTop: '5px', fontFamily: "'Ubuntu', sans-serif", color: 'gray'}}>
                {data.description}
            </p>

            <div style={{marginTop: '10px', fontFamily: "'Ubuntu', sans-serif"}}>
              <span>
                  {data.status === 'open' ? (
                      <font color="green" style={iconAndTextStyle}>
                          <CheckCircleIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                          <span>Open</span> &nbsp;
                          { data.status_text != null && data.status_text !== '' ?
                              ('(' + data.status_text + ')') : '' }
                      </font>
                  ) : data.status === 'closed' && data.status_text === 'not on duty' ? (
                      <font color="secondary" style={iconAndTextStyle}>
                          <BedtimeOffIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                          <span>Closed</span>
                      </font>
                  ) : data.status === 'closed' && data.status_text === 'high traffic' ? (
                      <font color="#1976d2" style={iconAndTextStyle}>
                          <AcUnitIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                          <span>Temporarily Frozen</span>
                      </font>
                  ) : (
                      <font color={ data.status === 'closed' ? 'error' : 'grey' } style={iconAndTextStyle}>
                          <DoNotDisturbOnIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                          <span style={{textTransform: 'capitalize'}}>
                              { data.status }
                          </span> &nbsp;
                          { data.status_text != null && data.status_text !== '' ?
                              ('(' + data.status_text + ')') : '' }
                      </font>
                  )}
              </span>

              <span style={{display: 'flex', color: 'gray', marginTop: '0.5rem',
                alignItems: 'center', flexWrap: 'wrap', justifyContent: 'left'}}>
                      <GroupIcon fontSize="inherit" sx={{paddingRight: '4px'}} />
                      <span>
                       { pluralize(data.helpee_num, "student", "students") } on the queue
                      </span>
              </span>
            </div>
            
          </CardContent>
            { adminView ? (
              <CardActions>
                <Button size="small" variant="outlined"
                  onClick={handleRoomUpdateDialogOpen(data.room_id)}
                >
                  Setting
                </Button>
                <Button size="small" variant="outlined"
                  onClick={handleRoomDeleteDialogOpen(data.room_id)}
                  color="error"
                >
                  Delete
                </Button>
              </CardActions>
            ) : null }
        </Card>
      </Grid>
    );
  });

  if (isEmptyList) {
    listItems = (
      <Grid container m={10}>
        <Grid item xs={12} align="center">
          <Typography variant="h4" color="text.secondary">
            There is no room here yet
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Backdrop 
        sx={{ color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1, 
          // position: "absolute" 
        }} 
        open={loading > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h4" component="div" mt={8} mb={3} pl={3} pr={3}
        sx={{fontWeight: 'bold'}}
      >
        Welcome to CS 128 Virtual Office!
      </Typography>
      <Box mb={3}>
        <Grid container>
          <Grid item xs align="right">
            { adminView ? (
              <Button 
                variant="contained"
                onClick={handleCreate()}
                sx={{
                  marginRight: 6, 
                }}
              >
                Create Room
              </Button>
            ) : null }

            { hasAdminAuth() ? (
              <React.Fragment>
                <small style={{fontFamily: "'Ubuntu', sans-serif", color: 'gray'}}>
                  Admin View
                </small>
                <Switch
                  checked={adminView}
                  onChange={handleAdminViewSwitchChange()}
                />
              </React.Fragment>
            ) : null }
            
          </Grid>
        </Grid >
      </Box>

      <Grid container 
        rowSpacing={3}
        columnSpacing={3}
        alignItems="stretch"
        pl={3}
        pr={3}
      >
        { listItems }
      </Grid>

      <RoomUpdateDialog 
        onApiThrow={props.onApiThrow}
        open={roomUpdateDialogOpen}
        roomId={roomUpdateDialogRoomId}
        onClose={handleUpdateDialogClose()}
        onUpdateRoomInfo={updateRoomList}
        adminView={adminView}
      />

      <RoomDeleteDialog
        onApiThrow={props.onApiThrow}
        open={roomDeleteDialogOpen}
        roomId={roomDeleteDialogRoomId}
        onClose={handleDeleteDialogClose()}
        onUpdateRoomInfo={updateRoomList}
      />
    </div>
  );
});

function RoomList(props) {
  let roomListComponentRef = React.createRef();

  const handleRoomListUpdateOnReceived = () => {
    return (message) => {
      roomListComponentRef.current.updateRoomList(message);
    };
  };

  return (
    <React.Fragment>
      <ActionCableConsumer
        channel={{ channel: "RoomListUpdateChannel" }}
        onReceived={handleRoomListUpdateOnReceived()}
      />
      <RoomListComponent onRef={roomListComponentRef} {...props} />
    </React.Fragment>
  );
}

export default RoomList;
