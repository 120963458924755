import React, { useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import { Backdrop, Button, ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';
import { Route, Routes, useNavigate  } from "react-router-dom";

import RoomInfo from "./RoomInfo";
import HelpeeInfo from './HelpeeInfo';
import HelperInfo from './HelperInfo';
import { Box } from '@mui/system';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { observer } from 'mobx-react'
import { hasStaffAuth } from './userInfo';
import ErrorIcon from '@mui/icons-material/Error';

class ErrorPage extends React.Component {
  render() {
    return (
      <Grid container align="center">
        <Grid item xs={12}  mt={5}>
          <ErrorIcon sx={{
            fontSize: '60px'
          }} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="h1" sx={{
            fontWeight: 'bold', 
            fontFamily: 'Comic Sans MS', 
          }}>
            { this.props.errorPageInfo.status }
          </Typography>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Typography variant="h5">
            { this.props.errorPageInfo.description }
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default ErrorPage;
