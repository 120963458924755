import React from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';

class ZoomOAuth2Callback extends React.Component {
  state = {
    authState: 0, 
    message: '', 
  };

  componentDidMount() {
    const apiPath = '/api/zoom/oauth2_callback';
    axios.get(apiPath + window.location.search)
      .then(res => {
        if (res.data.data.next_to == null || res.data.data.next_to === '') {
          // do nothing
          this.setState({ authState: 1, redirecting: false });
        } else {
          this.setState({ authState: 1, redirecting: true });
          window.location.href = res.data.data.next_to;
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401 && error.response.data.code === 60) {
          this.setState({ authState: 2, message: error.response.data.message, 
            next_to: error.response.data.data.next_to });
        } else {
          this.setState({ authState: 3 });
          this.props.onApiThrow(apiPath, error);
        }
      });
  }

  handleZoomSignIn() {
    return () => {
      const apiPath = '/api/zoom/oauth2';
      axios.get(apiPath, 
        { params: {
          next_to: this.state.next_to
        } })
        .then(res => {
          window.location.href = res.data.data.auth_url;
        })
        .catch(error => {
          this.props.onApiThrow(apiPath, error);
        });
    }
  }

  render() {
    var ret = null;
    switch (this.state.authState) {
      case 0:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Zoom Authorizing ...
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 1:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Zoom Authorization Success
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {this.state.redirecting ? 
                  'Redirecting ...' : 
                  'You can now close this page to continue. '}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 2:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Zoom Authorization Failed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  { this.state.message }
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant="contained" onClick={ this.handleZoomSignIn() }>retry sign in</Button>
              </Grid>
            </Grid>
          </div>
        );
        break;
      case 3:
        ret = (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Zoom Authorization Failed
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant="contained" onClick={ this.handleZoomSignIn() }>retry sign in</Button>
              </Grid>
            </Grid>
          </div>
        );
        break;
    }
    return (
      <div>
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.authState === 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {ret}
      </div>
    );
  }
}

export default ZoomOAuth2Callback;
