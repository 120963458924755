import { makeFullNameWithNetIdText } from "./commonHelperMethods";

export const getUserInfo = () => {
  let rawUserInfo = localStorage.getItem('userInfo');
  if (rawUserInfo === 'undefined') { return null; }
  return JSON.parse(rawUserInfo);
};

export const setUserInfo = (userInfo) => {
  return localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const isUserInfoComplete = () => {
  const userInfo = getUserInfo();
  return userInfo != null && 
    userInfo.email != null && 
    userInfo.last_name != null && 
    userInfo.first_name != null && 
    userInfo.net_id != null && 
    userInfo.user_type != null;
};

export const getUserNetId = () => {
  const userInfo = getUserInfo();
  return userInfo.net_id;
};

export const getUserFullNameWithNetIdText = () => {
  const userInfo = getUserInfo();
  return makeFullNameWithNetIdText(userInfo);
};

export const getUserEmail = () => {
  const userInfo = getUserInfo();
  return userInfo.email;
};

export const getUserType = () => {
  const userInfo = getUserInfo();
  return userInfo.user_type;
};

export const hasGuestAuth = () => {
  const userType = getUserType();
  return userType === 'admin' || userType === 'staff' || userType === 'student' || userType === 'guest';
};

export const hasStudentAuth = () => {
  const userType = getUserType();
  return userType === 'admin' || userType === 'staff' || userType === 'student';
};

export const hasStaffAuth = () => {
  const userType = getUserType();
  return userType === 'admin' || userType === 'staff';
};

export const hasAdminAuth = () => {
  const userType = getUserType();
  return userType === 'admin';
};
