import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Backdrop, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Input, InputLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Box } from '@mui/system';
import { getUserFullNameWithNetIdText } from './userInfo';
import { makeFullNameWithNetIdText } from './commonHelperMethods';

class CancelHelpHelpeeDialog extends React.Component {
  state = {
    inputNotificationContent: '', 
    inputNotificationSendAnoymously: false, 
    prevOpen: false, 
    loading: 0, 
    inputLocation: '', 
  };

  constructor(props) {
    super(props);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }
  
  startLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading + 1, 
    }));
  }

  stopLoading() {
    this.setState((prevState) => ({
      loading: prevState.loading - 1, 
    }));
  }

  fillDefault() {
    const defaultNotificationContent = '';
    // const defaultNotificationContent = 
    //   this.props.roomInfo.label + ': We missed you. Please join the conference within 1 minute. ';
    this.setState({
      inputNotificationContent: defaultNotificationContent, 
      inputNotificationSendAnoymously: true, 
      inputLocation: ''
    });
  }

  componentDidMount() {
    this.fillDefault();
  }

  componentDidUpdate() {
    if (this.state.prevOpen !== this.props.open) {
      this.setState({ prevOpen: this.props.open });
      if (this.props.open) {
        this.fillDefault();
      }
    }
  }

  handleInputChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSwitchChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  handleSubmit() {
    return () => {
      this.startLoading();
      const apiPath = '/api/rooms/' + this.props.roomInfo.room_id + '/helpers/cancel_help';
      axios.get(apiPath, { params: {
          helpee_id: this.props.helpee.helpee_id, 
          notification_content: this.state.inputNotificationContent, 
          notification_send_anonymously: this.state.inputNotificationSendAnoymously, 
          location: this.state.inputLocation
        } })
        .then(res => {
          this.props.onUpdateHelpeeList(res.data.data.update_info);
          this.stopLoading();
          this.props.onClose();
        })
        .catch((error) => {
          this.stopLoading();
          this.props.onApiThrow(apiPath, error);
        });
    };
  }

  render() {
    if (!this.props.open) {
      return null;
    }
    
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <Backdrop 
          sx={{ color: '#fff', 
            zIndex: (theme) => theme.zIndex.drawer + 1, 
          }}
          open={this.state.loading > 0}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{'Cancel Help: ' + makeFullNameWithNetIdText(this.props.helpee.helpee_user)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The notification will be pushed to the student's end. 
            If you do not want to push a notification, please make the text field empty. 
          </DialogContentText>
          <Box mt={1}>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Notification</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.inputNotificationContent}
                      onChange={this.handleInputChange('inputNotificationContent')}
                      multiline
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControlLabel control={<Switch 
                    checked={this.state.inputNotificationSendAnoymously}
                    onChange={this.handleSwitchChange('inputNotificationSendAnoymously')}
                  />} label="Send Anonymously" />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Location</InputLabel>
                    <Input
                      autoFocus
                      value={this.state.inputLocation}
                      onChange={this.handleInputChange('inputLocation')}
                      multiline
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.handleSubmit()}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CancelHelpHelpeeDialog;
